import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import "./styles.scss";
import {
	getEmail,
	getPhoneNumber,
	getPropertyId,
	getReservationId,
	removeItemFromLocalStorage
} from "../../common/utils/localStorageUtils";
import {
	getReservationsByIdService,
	postUpdateReservation
} from "../Login/service";
import { LOGIN_SERVICES } from "../Login/constants";
import { MessageButton } from "../../components/MessageButton";

export const CheckInSuccess = (props) => {
	const [showButton, setShowButton] = useState(false);
	const [reservationId, setReservationId] = useState(null);

	useEffect(() => {
		window.scroll({
			top: 0,
			behavior: "auto"
		});
		checkShowButton();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const signOut = async () => {
		//cognito sign-out
		await Auth.signOut();
		//clear local storage variables
		removeItemFromLocalStorage("email");
		removeItemFromLocalStorage("approvedEmails");
		removeItemFromLocalStorage("accessToken");
		removeItemFromLocalStorage("approvedPhones");
		removeItemFromLocalStorage("timerStartsTime");
	};
	const checkShowButton = async () => {
		const reservationId = await getReservationId();
		const reservations = await getReservationsById(reservationId);

		if (!reservations) {
			await signOut();
			return;
		}

		const reservationToPrecheckin = reservations.some((r) => !r.pre_checkin);
		if (reservationToPrecheckin) {
			setShowButton(true);
		} else {
			await signOut();
		}
	};

	useEffect(() => {
		(async () => {
			setReservationId(await getReservationId());
			if (reservationId) {
				const email = await getEmail();
				await postUpdateReservation(LOGIN_SERVICES.postUpdateReservation.apiEndpoint, {
					email,
					reservationId
				}).catch((e) => console.log(e));
			}
		})();
	}, [reservationId]);

	const getReservationsById = async (reservationId) => {
		try {
			const response = await getReservationsByIdService(
				LOGIN_SERVICES.getReservationsById.apiEndpoint,
				{
					reservationId
				}
			);
			if (response) {
				return response;
			}
		} catch (error) {
			redirectToErrorScreen(error);
		}
	};

	const goToLogin = async () => {
		const propertyId = await getPropertyId();
		const encryptMobileNo = await getPhoneNumber();
		const url = `/?phone=${encryptMobileNo}&reservationId=${reservationId}&propertyId=${propertyId}`;
		props.history.push(url);
	};

	const redirectToErrorScreen = (error) => {
		props.history.push("/error");
	};

	return (
		<div className="success-checkin-wrap">
			<div className="success-checkin">
				<h4>
					Congratulations!
					<span>Pre Check-in successful.</span>
				</h4>
				<p>
					Please allow us to verify the documents you have submitted. Once verified, you
					will receive your digital room key via text and email at check-in time.
				</p>
				<br />
				{showButton && (
					<button onClick={goToLogin} className="btn black-btn">
						Next Pre Check-in
					</button>
				)}
			</div>

			<div style={{ paddingTop: "100px" }}>
				<MessageButton />
			</div>
		</div>
	);
};
